<template>
    <div class="main">
    <p class="breadCrumb">{{$route.query.metaType==1?'首页':'学习'}} > 课程</p>
    <div class="bgcol">
        <img src="../assets/images/xaioren.png" alt="">
        <p>课程发生调整，暂时无法学习，看看其它课程吧</p>
        <el-button type="primary" round @click="$router.push('/search')">看看其他课程</el-button>
    </div>
    </div>
</template>

<script>
    export default {
        name: "nolearn"
    }
</script>

<style scoped lang="scss">
    .main{ height: calc(100% - 67px)}
    .breadCrumb {
        padding: 20px 0;
        box-sizing: border-box;
        font-size: 14px;
    }
.bgcol{
    background: #fff; height: 100%;text-align: center; display: flex; justify-content: center; align-items: center; flex-direction: column;
    p{color: #999; font-size: 18px; line-height: 18px; padding: 20px 0 30px;}
}
</style>

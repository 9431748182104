<template>
  <div class="addWrap">
    <el-dialog
      title="加入课单"
      width="500px"
      :visible.sync="dialogVisible"
      @close="changeList"
    >
      <template slot="title">
        <div class="titleWrap">
          加入课单 <span>（请选择要加入的课单吧）</span>
        </div>
      </template>
      <div v-if="listList.length > 0">
        <div class="scrool">
          <div class="itemWrap" v-for="(item, index) in listList" :key="index">
            <div>{{ item.name }}</div>
            <el-button
              type="primary"
              plain
              round
              @click="add(item)"
              class="joins" v-if="item.id!=in_list"
              >加入</el-button
            >
            <el-button v-else
                    type="primary"
                    plain
                    round
                    class="joins"
            >已加入</el-button
            >
          </div>
        </div>
        <p class="moreBtn" @click="getMore">
          <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
        </p>
      </div>
      <div v-else>暂无课单</div>
      <div class="btnWrap">
        <el-button type="primary" round @click="createList">创建课单</el-button>
      </div>
      <CreateList ref="CreateList" @getList="getList"></CreateList>
    </el-dialog>
  </div>
</template>

<script>
import { addCourseToKD, myCourseList } from "../../assets/api.js";

import CreateList from "./createList.vue";
export default {
  props: ["project_id"],
  components: {
    CreateList,
  },
  data() {
    return {
      dialogVisible: false,
      listList: [],
      end_time: "",
      page: 1,
      btnMessage: "加载更多",
      in_list:''
    };
  },
  mounted() {},
  methods: {
    openInit(end_time,in_list) {
      this.dialogVisible = true;
      this.end_time = end_time;
      this.listList = [];
      this.in_list = in_list;
      this.getList(1);
    },
    getList(type) {
      if (type==1) {
        this.page = 1;
        this.listList = [];
      }
      const params = {
        school_id: JSON.parse(window.sessionStorage.getItem("infos")).siteid,
        page: this.page,
      };
      myCourseList(params).then((res) => {
        if (res.code == 0) {
          if (res.data.list) {
            if (type==1) {
              this.listList = res.data.list;
            } else {
              this.listList = this.listList.concat(res.data.list);
            }
          } else {
            this.btnMessage = "暂无更多";
          }
        } else {
          this.page--;
        }
      });
    },
    getMore() {
      if (this.btnMessage == "加载更多") {
        this.page++;
        this.getList(2);
      }
    },
    add(item) {
      const params = {
        school_id: JSON.parse(window.sessionStorage.getItem("infos")).siteid,
        course_id: this.$route.query.course_id,
        project_id: this.project_id,
        list_id: item.id,
        name: item.name,
        end_time: this.end_time,
      };
      addCourseToKD(params).then((res) => {
        if (res.code == 0) {
          this.$message.success(
            "成功加入课单！可到学习-我的课程-我的课单中查看此课程~"
          );
          this.$parent.getcourseInfo();
          this.dialogVisible = false;
        }
      });
    },
    createList() {
      this.$refs.CreateList.openInit();
    },
    changeList() {
      this.$parent.getcourseInfo();
    },
  },
};
</script>

<style scoped lang="scss">
.scrool {
  max-height: 300px;
  overflow-y: auto;
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
  }
  // /*定义滚动条轨道 内阴影+圆角*/
  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #fff;
  }
  // /*定义滑块 内阴影+圆角*/
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ddd;
  }
}
.addWrap {
  /deep/.el-dialog {
    border-radius: 16px;
    .el-dialog__header {
      padding: 20px;
      border-bottom: 1px solid #eeeeee;
      .titleWrap {
        font-size: 16px;
        span {
          color: #666;
        }
      }
      i {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .el-dialog__body {
      padding: 30px;
    }
  }
  .itemWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
  }
  .btnWrap {
    margin-top: 10px;
    text-align: center;
    .el-button {
      width: 126px;
      border-radius: 22px;
    }
  }
  .moreBtn {
    margin: 0 auto;
    margin-top: 30px;
    width: 100px;
    height: 30px;
    font-size: 14px;
    border-radius: 18px;
    border: 1px solid #e1e1e1;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    color: #666;
    &:hover{
        background:var(--change-color);
        border:1px solid var(--change-color);
        color:#fff;
      }
    i {
      font-size: 14px;
    }
  }
}

.joins {
  color: var(--change-color) !important;
  background: #fff !important;
  border-color: var(--change-color)!important;
}
.joins:hover {
  color: #fff !important;
  background: var(--change-color) !important;
  border-color: var(--change-color) !important;
}
.el-button--primary {
  border-color: var(--change-color) !important;
}
</style>

<template>
  <div class="courseWrap" v-if="msg != '课程不存在。'">
    <!--    <div style="background: #f6f7f8; width: 100%">-->
    <!--      <div class="topWrap main">-->
    <!--        <p class="breadCrumb">-->
    <!--          {{ $route.query.metaType == 1 ? "首页" : "学习" }} > 课程-->
    <!--        </p>-->
    <!--        <div class="topInfo">-->
    <!--          <img v-if="course.image" :src="course.image" alt="" />-->
    <!--          <img v-else :src="require('../../../assets/images/nocourse.png')" alt="" />-->
    <!--          <img-->
    <!--                  src="./assets/images/jl.png"-->
    <!--                  alt=""-->
    <!--                  style="position: absolute"-->
    <!--                  v-if="course.is_expired==1"-->
    <!--          />-->
    <!--          <div class="infoRight">-->
    <!--            <div class="rightTop">-->
    <!--              <p class="rightTitle">-->
    <!--                {{ course.title }}-->
    <!--              </p>-->
    <!--              <p class="rightTime">-->
    <!--                有效期：{{ course.start_time | formatimes("yyyy-MM-dd") }} ~-->
    <!--                {{ course.end_time | formatimes("yyyy-MM-dd") }}-->
    <!--              </p>-->
    <!--              <div class="rightCate">-->
    <!--                <span v-if="course.typename"-->
    <!--                  ><i class="iconfont icon-courseCate"></i-->
    <!--                  >{{ course.typename }}</span-->
    <!--                >-->
    <!--                <span v-if="course.is_required == 1"-->
    <!--                  ><i class="iconfont icon-courseCate"></i>必修</span-->
    <!--                ><span v-else-->
    <!--                  ><i class="iconfont icon-courseCate"></i>选修</span-->
    <!--                >-->
    <!--                <span class="scoreWrap">-->
    <!--                  <i class="iconfont icon-courseCate"></i-->
    <!--                  >{{ course.credit }}学分-->
    <!--                  <img-->
    <!--                    src="./assets/images/getScore.png"-->
    <!--                    alt=""-->
    <!--                    v-if="course.finish_rate == 100"-->
    <!--                  />-->
    <!--                </span>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="rightBottom">-->
    <!--              <div class="bottomLeft">-->
    <!--                &lt;!&ndash;-->
    <!--                <div class="bottomItem">-->
    <!--                  <span>{{ course.finish_rate }}</span-->
    <!--                  ><i>%</i><br />课程完成率-->
    <!--                </div>-->
    <!--                <div class="bottomItem">-->
    <!--                  <span>{{ course.class_rank }}</span-->
    <!--                  ><br />课程排名-->
    <!--                </div>-->
    <!--                <div class="bottomItem">-->
    <!--                  <span>{{ course.class_list_num }}</span-->
    <!--                  ><i>人</i><br />累计加入课单-->
    <!--                </div>&ndash;&gt;-->
    <!--              </div>-->
    <!--             &lt;!&ndash; <el-button-->
    <!--                type="primary"-->
    <!--                round-->
    <!--                @click="add"-->
    <!--                v-if="course.in_list == 0"-->
    <!--                :disabled="course.is_expired"-->
    <!--                ><i class="el-icon-circle-plus-outline"></i>加入课单</el-button-->
    <!--              >-->
    <!--              <el-button-->
    <!--                type="primary"-->
    <!--                round-->
    <!--                :disabled="course.is_expired"-->
    <!--                v-else-->
    <!--                @click="add"-->
    <!--                style="background: #a8bfff !important; border-color: #a8bfff"-->
    <!--                >已加入课单</el-button-->
    <!--              >&ndash;&gt;-->
    <!--              <el-button-->
    <!--                      type="primary"-->
    <!--                      round-->
    <!--                      @click="learns(course.id,course.class_id)"-->
    <!--                      v-if="course.in_list != 0&&course.is_expired==0"-->
    <!--              >去学习</el-button-->
    <!--              >-->
    <!--              <el-button-->
    <!--                      type="info"-->
    <!--                      round-->
    <!--                      v-if="course.is_expired==1"-->
    <!--              >已结课</el-button-->
    <!--              >-->
    <!--              <el-button-->
    <!--                      type="text"-->
    <!--                      round-->
    <!--                      v-if="course.is_expired!=1&&course.in_list == 0&&course.status==1"-->
    <!--              >课程暂不对公众开放</el-button-->
    <!--              >-->
    <!--              <el-button-->
    <!--                      type="info"-->
    <!--                      round-->
    <!--                      v-if="course.in_list == 0&&course.status==0"-->
    <!--              >已下架</el-button-->
    <!--              >-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="newlearn">
      <div class="newlearnWrap main clearfix">
        <img
                v-if="course_basics.course_images"
                :src="course_basics.course_images"
                alt=""
        />
        <img
                v-else
                :src="require('../../../assets/images/nocourse.png')"
                alt=""
        />
        <img
                src="./assets/images/jl.png"
                alt=""
                style="position: absolute"
                v-if="course_basics.is_expired == 1"
        />
        <div class="newlearnRight">
          <h4 class="newlearnRightTitle">
            {{ course_basics.course_title }}
          </h4>
          <h6 class="newlearnRightTime">{{ course_basics.course_validity }}</h6>
          <div class="newlearnRighttype">
            <span v-if="course_related.course_type.is_show == 1"
            ><i class="iconfont icon-courseCate"></i
            >{{ course_related.course_type.value }}</span
            >
            <span v-if="course_related.is_required.is_show == 1"
            ><i class="iconfont icon-courseCate"></i
            >{{ course_related.is_required.value }}</span
            >
            <span class="scoreWrap" v-if="course_related.credit.is_show == 1">
              <i class="iconfont icon-courseCate"></i
              >{{ course_related.credit.value }}学分
            </span>
          </div>
          <div class="rightBottom">
            <div class="bottomLeft">
              <p
                      v-if="
                  course_related.subject.is_show == 1 &&
                  course_related.subject.value != ''
                "
              >
                学科：{{ course_related.subject.value }}
              </p>
              <p
                      v-if="
                  course_related.specialty.is_show == 1 &&
                  course_related.specialty.value != ''
                "
              >
                院系专业：{{ course_related.specialty.value }}
              </p>
              <p
                      v-if="
                  course_related.subtitle.is_show == 1 &&
                  course_related.subtitle.value != ''
                "
              >
                {{ course_related.subtitle.value }}
              </p>
              <p
                      v-if="
                  course_related.summary.is_show == 1 &&
                  course_related.summary.value != ''
                "
              >
                {{ course_related.summary.value }}
              </p>
            </div>
            <el-button
                    type="primary"
                    round
                    @click="learns($route.query.course_id, course_basics.default_class_id)"
                    v-if="course_basics.in_list != 0 && course_basics.is_expired == 0"
            >去学习</el-button
            >
            <el-button type="info" round v-if="course_basics.is_expired == 1"
            >已结课</el-button
            >
            <el-button
                    type="text"
                    round
                    v-if="
                course_basics.is_expired != 1 &&
                course_basics.in_list == 0 &&
                course_basics.status == 1
              "
            >课程暂不对公众开放</el-button
            >
            <el-button
                    type="info"
                    round
                    v-if="course_basics.in_list == 0 && course_basics.status == 0"
            >已下架</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="bottomWrap">
      <div class="centerWrap main" v-if="projectFlag">
        <i class="el-icon-close" @click="projectFlag = false"></i>
        <div class="centerTitle">
          <i class="iconfont icon-courseCenter"></i>
          该课属于“{{ course.project_name }}”
          <el-button type="text" @click="plane(course.project_id)"
          >查看更多
            <i
                    class="el-icon-arrow-right"
                    style="font-size: 14px; color: #507fff; font-weight: bolder"
            ></i
            ></el-button>
        </div>
        <div class="centerItemWrap">
          <div
                  class="centerItem"
                  v-for="(item, index) in course.pro_course_list"
                  :key="index"
                  @click="learns(item.id)"
          >
            <img :src="item.image" alt="" />
            <div class="ellWrap">
              <div class="ellipsis_two">
                <el-tag
                        size="mini"
                        v-if="item.course_type_name"
                        style="background: #ebf0ff; color: #507fff; border: none"
                >{{ item.course_type_name }}</el-tag
                >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
              class="bottomCourseWrap main"
              :style="{ marginTop: projectFlag ? '20px' : '-65px' }"
      >
        <div class="bottomLeftWrap">
          <ul>
            <template v-for="(item, index) in leftMenuList">
              <li

                      :key="index"
                      @click="changeMenu(item)"
                      v-if="item.is_show == 1"
                      class="menuItem"
                      :class="nowActive == item.retype ? 'chooseItem' : ''"
              >
                <i :class="'iconfont ' + item.icon"></i>{{ item.title }}
              </li>
            </template>
          </ul>
        </div>
        <div class="bottomRightWrap">
          <NewCourseInfo
                  ref="CourseInfo"
                  v-if="nowActive == 'introduction'"
                  :course_customize="course_customize"
                  :iShow="iShow"
          ></NewCourseInfo>
          <CoursePlan
                  ref="CoursePlan"
                  v-if="nowActive == 'arrangement'"
                  :modelFlag="course.is_stage"
                  :expired="course.is_expired"
                  :in_list="course.in_list"
          ></CoursePlan>
          <CourseData
                  ref="CourseData"
                  :ssid="ssid"
                  v-if="nowActive == 3"
          ></CourseData>
          <CourseNote ref="CourseNote" v-if="nowActive == 4"></CourseNote>
          <CourseEval
                  ref="CourseEval"
                  v-if="nowActive == 'evaluation'"
                  :is_expired="course.is_expired"
                  :appraisal_star="course.appraisal_star"
                  :in_list="course.in_list"
          ></CourseEval>
        </div>
      </div>
    </div>
    <AddList ref="AddList" :project_id="course.project_id"></AddList>
    <ScoreDia ref="ScoreDia"></ScoreDia>
  </div>
  <nolearn v-else></nolearn>
</template>

<script>
  import AddList from "./components/infoCom/addList.vue";
  import ScoreDia from "./components/infoCom/scoreDia.vue";
  // import CourseInfo from "./components/courseInfo.vue";
  import NewCourseInfo from "./components/NewCourseInfo";
  import CoursePlan from "./components/coursePlan.vue";
  import CourseData from "./components/courseData.vue";
  import CourseNote from "./components/courseNote.vue";
  import CourseEval from "./components/courseEval.vue";
  import nolearn from "./components/nolearn.vue";
  import NProgress from "nprogress";
  import "nprogress/nprogress.css";
  NProgress.configure({ showSpinner: false });
  import { courseInfo, portalInfo } from "./assets/api.js";
  export default {
    components: {
      AddList,
      ScoreDia,
      NewCourseInfo,
      CoursePlan,
      CourseData,
      CourseNote,
      CourseEval,
      nolearn,
    },

    data() {
      return {
        course: {},
        leftMenuList: [
          // {
          //   id: 1,
          //   name: "课程介绍",
          //   icon: "icon-kcjs",
          // },
          // {
          //   id: 2,
          //   name: "课件安排",
          //   icon: "icon-kjap",
          // },
          // {
          //   id: 3,
          //   name: "课后资料",
          //   icon: "icon-khzl",
          // },
          // {
          //   id: 4,
          //   name: "课程笔记",
          //   icon: "icon-kcbj",
          // },
          // {
          //   id: 5,
          //   name: "课程评价",
          //   icon: "icon-kcpj1",
          // },
        ],
        nowActive: "introduction",
        is_stage: "",
        projectFlag: true,
        msg: "",
        ssid: "",
        course_customize: [],
        course_basics: {},
        course_related: {},
        course_settings: {},
        iShow:false
      };
    },

    mounted() {
      this.getcourseInfo(1);
      this.portalInfo();
    },
    watch: {
      $route: {
        handler: function () {
          this.nowActive = "introduction";
          this.getcourseInfo(1);
        },
        deep: true,
      },
    },
    methods: {
      portalInfo() {
        let params = {
          course_id: this.$route.query.course_id,
        };
        portalInfo(params).then((res) => {
          if (res.code == 0) {
            this.course_customize = res.data.course_customize;
            let iShow=this.course_customize.findIndex(item=>item.is_show==1)
            this.iShow=iShow==-1?true:false
            this.course_basics = res.data.course_stable.course_basics;
            this.course_related = res.data.course_stable.course_related;
            this.course_settings = res.data.course_stable.course_settings;
            // console.log(this.course_basics );
            if (this.course_settings.length > 0) {
              this.course_settings.map((item) => {
                if (item.retype == "introduction") {
                  this.$set(item, "icon", "icon-kcjs");
                } else if (item.retype == "arrangement") {
                  this.$set(item, "icon", "icon-kjap");
                } else if (item.retype == "evaluation") {
                  this.$set(item, "icon", "icon-kcpj1");
                }
                this.leftMenuList.push(item);
              });
            }
          }
        });
      },
      learns(id, cst) {
        // alert(cst)
        this.$router.push({
          path: "/newlearn",
          query: {
            course_id: id,
            cst_id: cst,
          },
        });
      },
      plane(id) {
        this.$router.push({
          path: "plan_detail",
          query: {
            project_id: id,
            metaType: this.$route.query.metaType,
          },
        });
      },
      add() {
        this.$refs.AddList.openInit(this.course.end_time, this.course.in_list);
      },
      changeMenu(item) {
        this.nowActive = item.retype;
        if (item.id == "introduction") {
          this.getcourseInfo(2);
        }
      },
      getcourseInfo(type) {
        NProgress.start();
        let params = {
          course_id: this.$route.query.course_id,
        };
        courseInfo(params).then((res) => {
          if (res.code == 0) {
            NProgress.done();
            this.course = res.data;
            // this.$refs.CoursePlan.in_list = res.data.in_list
            this.ssid = res.data.sou_school_id;
            if (this.course.pro_course_list.length == 0) {
              this.projectFlag = false;
            }
            // this.$refs.CourseInfo.dataList=[]
            // this.$refs.CourseInfo.dataList = res.data;
            this.$nextTick(() => {
              window.sessionStorage.setItem("course", JSON.stringify(res.data));
            });
            if (type == 1) {
              if (res.data.in_list != 0) {
                this.nowActive = "introduction"; //记得改为arrangement
              }
            }
          } else {
            this.msg = res.message;
          }
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .courseWrap {
    .newlearn {
      background: #f6f7f8;
    }
    .newlearnWrap {
      height: 380px;
      padding-top: 37px;
      box-sizing: border-box;
      display: flex;
      img {
        width: 465px;
        height: 260px;
        border-radius: 8px;
        margin-right: 55px;
      }
      .newlearnRight {
        display: flex;
        flex-direction: column;
        width: calc(100% - 520px);
        position: relative;
        .newlearnRightTitle {
          color: #333333;
          font-size: 26px;
          line-height: 36px;
          font-weight: normal;
          margin-top: 4px;
          max-height: 72px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .newlearnRightTime {
          color: #888;
          font-size: 16px;
          line-height: 20px;
          font-weight: normal;
          margin-top: 8px;
        }
        .newlearnRighttype {
          color: #666;
          font-size: 16px;
          line-height: 20px;
          font-weight: normal;
          margin-top: 6px;
          i {
            margin-right: 6px;
            font-size: 14px;
          }
          span {
            margin-right: 30px;
          }
        }
        .rightBottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          left: 0px;
          width: 100%;
          bottom: 106px;
          .bottomLeft {
            max-width: 75%;
            p {
              color: #333;
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 6px;
            }
            p:first-child {
              height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            p:last-child {
              max-height: 40px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          .el-button.is-round {
            padding: 10px 36px;
            i {
              font-size: 16px;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .topWrap {
      height: 433px;
      .breadCrumb {
        padding: 20px 0;
        box-sizing: border-box;
        font-size: 14px;
      }
      .topInfo {
        height: 260px;
        padding-right: 30px;
        box-sizing: border-box;
        display: flex;
        img {
          width: 465px;
          height: 260px;
          border-radius: 8px;
          margin-right: 55px;
        }
        .infoRight {
          padding: 20px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: calc(100% - 520px);
          .rightTop {
            font-size: 16px;

            .rightTitle {
              font-size: 26px;
              margin-bottom: 15px;
              color: #333;
            }
            .rightTime {
              margin-bottom: 15px;
              color: #888;
            }
            .rightCate {
              color: #666;
              span {
                margin-right: 30px;
                i {
                  margin-right: 5px;
                }
              }
              .scoreWrap {
                position: relative;
                img {
                  width: 36px;
                  height: 36px;
                  position: absolute;
                  right: -100%;
                  top: -50%;
                }
              }
            }
          }
          .rightBottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .bottomLeft {
              display: flex;
              .bottomItem {
                margin-right: 45px;
                color: #666;
                font-size: 14px;
                span {
                  font-size: 18px;
                  color: #fe4e00;
                  font-weight: bold;
                }
                i {
                  color: #fe4e00;
                }
                &:last-child {
                  padding-left: 35px;
                  position: relative;
                  &::after {
                    clear: both;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 5px;
                    width: 1px;
                    height: 40px;
                    background: #dedede;
                  }
                }
              }
            }
            .el-button.is-round {
              padding: 10px 20px;
              i {
                font-size: 16px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    .bottomWrap {
      background: #fff;
      border: 1px solid #fff;
      padding: 20px;
      box-sizing: border-box;
      .centerWrap {
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 0 20px rgba(5, 17, 55, 0.08);
        padding: 45px 25px 40px 30px;
        box-sizing: border-box;
        margin-top: -65px;
        position: relative;
        .el-icon-close {
          position: absolute;
          right: 25px;
          top: 30px;
          font-size: 20px;
          color: #9b9b9b;
          cursor: pointer;
        }
        .centerTitle {
          font-size: 20px;
          color: #333;
          margin-bottom: 30px;
          i {
            font-size: 19px;
            color: #bbbbbb;
            margin-right: 10px;
          }
          .el-button {
            font-size: 16px;
            margin-left: 20px;
            padding: 0;
          }
        }
        .centerItemWrap {
          margin-left: -35px;
          overflow: hidden;
          .centerItem {
            width: 20%;
            padding-left: 35px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            float: left;
            img {
              width: 100%;
              height: 130px;
              border-radius: 8px 8px 0 0;
            }
            div.ellWrap {
              border-radius: 0 0 8px 8px;
              border: 1px solid #eeeeee;
              border-top: none;
              color: #333;
              font-size: 14px;
              padding: 10px 10px 15px 10px;
              box-sizing: border-box;
              // height: 68px;
              box-sizing: border-box;
              overflow: hidden;
              .el-tag {
              }
              //文字超出两行省略
              .ellipsis_two {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                min-height: 42px;
                max-height: 42px;
              }
            }
          }
        }
      }
      .bottomCourseWrap {
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 0 20px rgba(5, 17, 55, 0.08);
        margin-top: 20px;
        display: flex;
        .bottomLeftWrap {
          padding: 30px 33px 30px 20px;
          box-sizing: border-box;
          border-right: 1px solid #eef1f3;
          ul {
            .menuItem {
              margin-bottom: 5px;
              padding: 7px 20px;
              border-radius: 6px;
              box-sizing: border-box;
              color: #333;
              font-size: 16px;
              cursor: pointer;
              width: 145px;
              height: 40px;
              i {
                color: #a6a6c1;
                font-size: 18px;
                margin-right: 10px;
              }
              &:hover {
                background: #f0f6ff;
              }
            }
            .chooseItem {
              background: var(--change-color);
              color: #fff;
              i {
                color: #fff;
              }
              &:hover {
                background: var(--change-color);
              }
            }
          }
        }
        .bottomRightWrap {
          width: calc(100% - 200px);
        }
      }
    }
    .footerWrap {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      .main {
        padding: 0 40px 0 223px;
        box-sizing: border-box;
      }
      .contentItem {
        padding: 15px 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;
        .leftItemNum {
          font-size: 20px;
          font-weight: bold;
          color: #b9bcc1;
          margin-right: 15px;
          width: 52px;
        }
        .leftItemImg {
          width: 34px;
          height: 34px;
          margin-right: 15px;
        }
        .rightItem {
          width: calc(100% - 90px);
          display: flex;
          align-items: center;
          justify-content: space-between;
          .rightLeft {
            padding-right: 65px;
            box-sizing: border-box;
            .leftTitle {
              font-size: 16px;
              margin-bottom: 10px;
            }
            .leftInfo {
              font-size: 14px;
              color: #999999;
              .tag {
                border-radius: 4px;
                line-height: 19px;
                padding: 0 6px;
                box-sizing: border-box;
                border: 1px solid #d8d8d8;
              }
            }
          }
          .statusWrap {
            display: flex;
            align-items: center;
            img {
              margin-right: 15px;
            }
            .btnWrap {
              width: 96px;
              height: 32px;
              line-height: 32px;
              text-align: center;
              background: #507fff;
              color: #fff;
              border-radius: 16px;
            }
          }
        }
        &:hover {
          .leftTitle {
            color: #507fff;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="scoreWrap">
    <el-dialog width="338px" :visible.sync="dialogVisible" :show-close="false">
      <p>恭喜《2021高数复习课程》学完了~共获得X学分</p>
      <div class="btnWrap">
        <el-button
          type="primary"
          round
          @click="dialogVisible = false"
          class="backBtn"
          >去看看</el-button
        >
        <el-button type="primary" round@click="dialogVisible = false"
          >知道了</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.scoreWrap {
  /deep/.el-dialog {
    border-radius: 16px;
    .el-dialog__header {
      padding: 20px;
    }
    .el-dialog__body {
      padding: 0 30px 30px;
    }
  }
  p {
    font-size: 16px;
    text-align: center;
    padding: 0 20px;
    box-sizing: border-box;
    color: #333;
  }
  .btnWrap {
    margin-top: 30px;
    text-align: center;
    .el-button {
      width: 126px;
      border-radius: 22px;
    }
    .backBtn {
      background: var(--change-darkcolor);
      border-color: var(--change-darkcolor);
    }
  }
}
</style>

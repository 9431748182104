<template>
  <div class="NewCourseInfo">
    <div v-if="!iShow">
      <template v-if="course_customize.length > 0">
        <div v-for="item in course_customize" :key="item.sort">
          <template v-if="item.is_show == 1 && item.retype == 1 &&item.content.value">
            <div class="titleWrap" v-if="item.title.is_show == 1">
              <img src="../assets/images/infoIcon.png" alt="" />
              {{ item.title.value }}
            </div>
            <div
                    class="CourseInfocon"
                    v-if="item.content.is_show == 1"
                    v-html="item.content.value"
            ></div>
          </template>
          <template v-if="item.is_show == 1 && item.retype == 2&&item.list.length > 0">
            <div class="titleWrap" v-if="item.title.is_show == 1">
              <img src="../assets/images/infoIcon.png" alt="" />
              {{ item.title.value }}
            </div>
            <ul class="NewCourseInfooul">
              <template v-for="(it, idx) in item.list" >
                <li v-if="it.is_show" :key="idx">
                  <img :src="it.head" alt="" class="NewCourseInfooulimg" />
                  <dl class="NewCourseInfoouldl">
                    <dt>
                      <span>{{it.name}}</span>
                      <i>{{it.school}}</i>
                      <i>{{it.title}}</i>
                    </dt>
                    <dd v-html="it.introduction"></dd>
                  </dl>
                </li>
              </template>

            </ul>
          </template>
          <template v-if="item.is_show == 1 && item.retype == 3&&item.list.length > 0">
<!--            <div class="titleWrap" v-if="item.title.is_show == 1">-->
<!--              <img src="../assets/images/infoIcon.png" alt="" />-->
<!--              {{ item.title.value }}-->
<!--            </div>-->
            <div class="NewCourseInfoimgList">
              <div v-for="(it, idx) in item.list" :key="idx">
                <img :src="it.big_image" alt="" />
              </div>
            </div>
          </template>
          <template v-if="item.is_show == 1 && item.retype == 4">
            <div class="titleWrap" v-if="item.title.is_show == 1">
              <img src="../assets/images/infoIcon.png" alt="" />
              {{ item.title.value }}
            </div>
            <div
                    class="CourseInfocon"
                    v-if="item.content.is_show == 1"
                    v-html="item.content.value"
            ></div>
          </template>
        </div>
      </template>
    </div>
    <div class="noDataWrap" v-else>
      <img src="../assets/images/noJs.png" alt="" />
      <p class="mt30">暂无课程介绍</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewCourseInfo",
  props: {
    course_customize: {
      type: Array,
      default(){
        return []
      },
    },
    iShow:{
      type: Boolean,
      default(){
        return false
      },
    }
  },
  data() {
    return {
      // dataList: [],
    };
  },
  // watch:{
  //   dataList:{
  //     handler:function (val) {
  //       console.log(val,2132323)
  //     },
  //     deep:true
  //   }
  // },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
  .noDataWrap {
    text-align: center;
    margin-top: 204px;
    margin-bottom: 300px;
    font-size: 16px;
    color: #999999;
  }
.NewCourseInfo {
  padding: 20px 30px;
  box-sizing: border-box;
  .titleWrap {
    margin-bottom: 16px;
    font-size: 20px;
    color: #333;
    display: flex;
    align-items: center;
    img {
      width: 27px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .CourseInfocon {
    color: #333;
    font-size: 16px;
    line-height: 30px;
    font-weight: normal;
    margin-bottom: 30px;
  }
  .NewCourseInfooul {
    li {
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      position: relative;
      margin-bottom: 20px;
      min-height: 150px;
      .NewCourseInfooulimg {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        position: absolute;
        left: 30px;
        top: 30px;
      }
      .NewCourseInfoouldl {
        padding-left: 149px;
        dt {
          padding-top: 20px;
          font-size: 16px;
          line-height: 20px;
          color: #333333;
          span {
            font-weight: bold;
            margin-right: 20px;
          }
          i {
            margin-right: 20px;
          }
        }
        dd {
          padding: 9px 0;
          font-size: 14px;
          line-height: 26px;
          color: #666;
        }
      }
    }
  }
  .NewCourseInfoimgList {
    padding-top: 10px;
    img {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }
}
</style>

<template>
  <div class="createWrap">
    <el-dialog
      title="创建课单"
      width="500px"
      :visible.sync="dialogVisible"
      append-to-body
    >
      <el-input
        v-model="name"
        placeholder="请输入课单名称,最多10个字符"
        maxlength="10"
      ></el-input>
      <div class="btnWrap">
        <el-button
          type="primary"
          round
          @click="dialogVisible = false"
          class="backBtn"
          >返回</el-button
        >
        <el-button type="primary" round @click="createList">创建课单</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { myCourseKDAdd } from "../../assets/api.js";
export default {
  data() {
    return {
      dialogVisible: false,
      name: "",
    };
  },
  mounted() {},
  methods: {
    openInit() {
      this.dialogVisible = true;
      this.name = "";
    },
    createList() {
      const params = {
        school_id: JSON.parse(window.sessionStorage.getItem("infos")).siteid,
        name: this.name,
      };
      myCourseKDAdd(params).then((res) => {
        if (res.code == 0) {
          this.dialogVisible = false;
          this.$message.success("创建成功");
          this.$emit("getList", 1);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 16px;
  .el-dialog__header {
    padding: 20px;
    border-bottom: 1px solid #eeeeee;
    font-size: 16px;
    i {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .el-dialog__body {
    padding: 50px 65px 30px;
  }
}

.btnWrap {
  margin-top: 100px;
  text-align: center;
  .el-button {
    width: 126px;
    border-radius: 22px;
  }
  .backBtn {
    background: var(--change-darkcolor) !important;
    border-color: var(--change-darkcolor)!important;
  }
}
</style>
